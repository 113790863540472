@import "index", "font", "basic", "layout";

@media (prefers-color-scheme: dark) {
  body {
    background: $dark;
    color: $light; } }

body > header, body > article, body > footer {
  padding: 1.5em; }

@media (min-width: 32em) {
  body > header, body > article, body > footer {
    padding: 1.7em calc(38% - 12em); } }



body > header {
  background: $dark;
  color: $light;
  border-bottom: 1px solid rgba($light, 0.05); }



body > footer {
  box-shadow: 0 0 .6em rgba($dark, 0.04) inset;
  border-top: 1px solid reduce(16);
  opacity: .9; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

body > header, body > footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

article {
  flex-grow: 1; }

article header {
  margin-bottom: 1.5em; }

article header h1, article.home-excerpts header h2 {
  font-size: 1.8em;
  margin: 0 0 .1em; }

nav {
  margin: .5em -.8em; }

nav a {
  margin: .5em .8em; }
